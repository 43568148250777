import DeleteButton from '../buttons/DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import Search from 'antd/lib/input/Search';
import { Col, Spin, Switch, Row, Table, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { ajax, createNotification, createNotificationShort, filterData, formatDate, getHistory } from '../../helper';
import { deleteUser, getUsers } from '../../actions/admin';
import { getTranslate } from 'react-localize-redux';
import { isEqual } from 'lodash';
import { confirmationModal } from '../modals/ConfirmationModal';
import { showErrorDetailsModal } from '../modals/DeleteUserErrorDetails';
import { toggleModal } from '../../actions/modal';
import theme from '../../theme';
import { CREATE_NEW_USER } from '../../constants/modalNames';
import { TAB_KEY_DEFAULT_CONTAINERS } from '../../constants/strings';

const FILTER_FIELDS = ['email', 'oid', 'credentialType', 'language', 'profile'];

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        userList: [],
        selectedUserList: [],
        loading: true,
        searchText: '',
        adminToggleChecked: false,
        numberOfSelectedUsers: 0,
        selectAllInTable: false,
        enableMultipleSelect: false,
        tokenDeleteButtonDisabled: false,
        totalRows: 0,
        orderBy: 'created',
        order: 'DESC',
        userLimit: 10,
        userOffset: 0,
        currentPage: 0,
    };

    componentDidMount() {
        this._isMounted = true;
        this.refreshUserList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    refreshUserList() {
        this.getUsers(
            this.state.searchText,
            this.state.orderBy,
            this.state.order,
            this.state.userLimit,
            this.state.userOffset,
            this.state.adminToggleChecked
        );
    }

    onSearch = (value) => {
        this._isMounted &&
            this.setState({
                searchText: value,
                userOffset: value === '' ? this.state.userOffset : 0,
                currentPage: value === '' ? this.state.currentPage : 0,
                loading: true,
            });
        this.getUsers(
            value,
            this.state.orderBy,
            this.state.order,
            this.state.userLimit,
            value === '' ? this.state.userOffset : 0,
            this.state.adminToggleChecked
        );
    };

    onChangeToggle = (checked) => {
        this._isMounted && this.setState({ adminToggleChecked: checked, userOffset: 0, currentPage: 0 });
        this.getUsers(this.state.searchText, this.state.orderBy, this.state.order, this.state.userLimit, 0, checked);
    };

    onDeleteUsers = async (users) => {
        this._isMounted && this.setState({ loading: true });
        const { translate } = this.props;
        let errors = [];
        let successEmails = [];
        await Promise.all(
            users.map(async (email) => {
                try {
                    const response = await this.props.deleteUser(email);
                    createNotificationShort(this.props.translate, {
                        message: translate('messages.success.adminDeleteUserSuccess', { email }),
                        type: 'success',
                    });
                    successEmails.push(email);
                    return response;
                } catch (e) {
                    if (e.response.data.data && !isEqual(e.response.data.data, {})) {
                        errors.push(e.response.data.data);
                    } else {
                        createNotification(this.props.translate, e.response.data.code, {
                            message: translate('errorCodes.USER_DELETE_ERROR'),
                            type: 'error',
                        });
                    }
                }
            })
        );
        if (errors.length > 0) {
            showErrorDetailsModal(errors, translate);
        }

        this._isMounted &&
            this.setState((prev) => ({
                selectedUserList: [],
                numberOfSelectedUsers: 0,
                userList: prev.userList.filter((user) => !successEmails.includes(user.email)),
                loading: false,
            }));
    };

    filterUserList = () => {
        const { searchText, userList } = this.state;
        let filteredUserList = [...userList];

        if (searchText) {
            filteredUserList = filterData(filteredUserList, searchText, FILTER_FIELDS);
        }
        return filteredUserList;
    };

    handleOnChange = (selectedKeys, selectedRows) => {
        this._isMounted &&
            this.setState({
                selectedUserList: selectedKeys,
                numberOfSelectedUsers: selectedRows.length,
                selectAllInTable: false,
            });
    };

    handleSelectAll = (selected, selectedRows) => {
        if (selectedRows) {
            this._isMounted &&
                this.setState({
                    selectedUserList: selectedRows.map((user) => user.email),
                    numberOfSelectedUsers: selectedRows.length,
                    selectAllInTable: true,
                });
        } else {
            this._isMounted &&
                this.setState({
                    selectedUserList: [],
                    numberOfSelectedUsers: 0,
                    selectAllInTable: false,
                });
        }
    };

    handleRowSelectionChange = (enable) => {
        this._isMounted &&
            this.setState({
                enableMultipleSelect: enable,
            });
    };

    handleOnUserCreated = () => {
        this.refreshUserList();
    };

    resetFilterIfSearchFieldIsEmpty = (e) => {
        if (e.target.value === '' && this.state.searchText !== '') {
            this._isMounted && this.setState({ searchText: '' });
        }
    };

    handleTokenDelete = (email) => {
        ajax()
            .put(`/admin/users/${email}`, { deleteToken: true })
            .then((response) => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('messages.success.updateUserSuccess'),
                    type: 'success',
                });
                let updatedUserList = [...this.state.userList];
                const elementsIndex = this.state.userList.findIndex((element) => element.email === response.data.email);
                updatedUserList[elementsIndex] = response.data;
                this._isMounted && this.setState({ userList: updatedUserList });
                return response;
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.updateUserFailed'),
                    type: 'error',
                });
                throw e;
            });
    };

    handleSecondFactor = (email, secondFactor) => {
        ajax()
            .put(`/admin/users/${email}`, { secondFactor: secondFactor })
            .then((response) => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('messages.success.updateUserSuccess'),
                    type: 'success',
                });
                let updatedUserList = [...this.state.userList];
                const elementsIndex = this.state.userList.findIndex((element) => element.email === response.data.email);
                updatedUserList[elementsIndex] = response.data;
                this._isMounted && this.setState({ userList: updatedUserList });
                return response;
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.updateUserFailed'),
                    type: 'error',
                });
                throw e;
            });
    };

    onTableChange = (pagination, filters, sorter) => {
        const orderBy = sorter.columnKey ? sorter.columnKey : 'created';
        const order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        this._isMounted &&
            this.setState({
                orderBy: orderBy,
                order: order,
                userLimit: pagination.pageSize,
                userOffset: (pagination.current - 1) * pagination.pageSize,
                currentPage: pagination.current,
                loading: true,
            });
        this.getUsers(
            '',
            orderBy,
            order,
            pagination.pageSize,
            (pagination.current - 1) * pagination.pageSize,
            this.state.adminToggleChecked
        );
    };

    getUsers = (filter, orderBy, order, userLimit, userOffset, onlyAdmins) => {
        this.props
            .getUsers(filter, orderBy, order, userLimit, userOffset, onlyAdmins)
            .then(
                (response) =>
                    this._isMounted &&
                    this.setState({
                        userList: response.data.userListFiltered === undefined ? [] : response.data.userListFiltered,
                        totalRows: response.data.allUserCount === undefined ? [] : response.data.allUserCount,
                    })
            )
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.translate('notifications.getData.failure'),
                    type: 'error',
                });
            })
            .finally(() => {
                this._isMounted && this.setState({ loading: false });
            });
    };

    render() {
        const { selectedUserList, loading, numberOfSelectedUsers } = this.state;
        const { translate, showFileListOfUser, editUser, toggleNewUserModal } = this.props;
        const filteredUserList = this.filterUserList();

        const columns = getColumns(
            translate,
            this.onDeleteUsers,
            editUser,
            showFileListOfUser,
            this.handleTokenDelete,
            this.handleSecondFactor
        );

        const rowSelection = this.state.enableMultipleSelect
            ? {
                  onChange: this.handleOnChange,
                  onSelectAll: this.handleSelectAll,
                  selectedRowKeys: this.state.selectedUserList,
              }
            : undefined;

        return (
            <div>
                <Row type="flex" align="middle" gutter={[24, 0]} style={{ marginBottom: '1rem' }}>
                    <Col sm={10} md={10} lg={8}>
                        <Search
                            onSearch={this.onSearch}
                            onChange={this.resetFilterIfSearchFieldIsEmpty}
                            placeholder={this.props.translate('adminPage.common.searchPlaceholder')}
                            allowClear
                        />
                    </Col>
                    <Col>
                        <Button
                            icon="plus"
                            style={{ margin: '1rem' }}
                            onClick={() => toggleNewUserModal(this.handleOnUserCreated)}
                        >
                            {translate('adminPage.users.newUser')}
                        </Button>
                    </Col>
                    <Col>
                        {this.props.translate('adminPage.users.selectMultiple')}
                        <Switch
                            style={{ marginLeft: '10px' }}
                            checked={!!rowSelection}
                            onChange={this.handleRowSelectionChange}
                        />
                    </Col>
                    <Col>
                        {this.props.translate('adminPage.users.numberOfSelectedUsers') + ' ' + numberOfSelectedUsers}
                    </Col>
                    <Col>
                        {!!rowSelection && (
                            <DeleteButton
                                disabled={!selectedUserList.length}
                                key={'delete'}
                                onClick={() => this.onDeleteUsers(selectedUserList)}
                                title={translate('buttons.delete.container')}
                            />
                        )}
                    </Col>
                    <Col style={{ flex: 1, textAlign: 'right' }}>
                        <Switch
                            checkedChildren={translate('adminPage.users.unCheckedChildren')}
                            unCheckedChildren={translate('adminPage.users.checkedChildren')}
                            onChange={this.onChangeToggle}
                        />
                    </Col>
                </Row>
                <Row>
                    <Spin spinning={loading}>
                        <Table
                            style={{ overflow: 'auto' }}
                            columns={columns}
                            dataSource={filteredUserList.map((d) => ({
                                key: d.email,
                                email: d.email,
                                userType: translate(`userRole.${d.role}`),
                                credentialType: translate(`userCredential.${d.credentialType}`),
                                oid: d.oid,
                                created: formatDate(d.created, this.props.translate('language')),
                                language: translate(`languages.${d.language}`),
                                profile: translate(`user.profile.${d.profile}`),
                                tsUser: d.tsUser,
                                token: d.token,
                                secondFactor: d.secondFactor,
                            }))}
                            rowSelection={rowSelection}
                            locale={{ emptyText: this.props.translate('noData') }}
                            onChange={this.onTableChange}
                            pagination={{
                                current: this.state.currentPage,
                                total: this.state.totalRows,
                                position: 'both',
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30'],
                                locale: { items_per_page: '' },
                            }}
                        />
                    </Spin>
                </Row>
            </div>
        );
    }
}

UserList.propTypes = {
    editUser: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    showFileListOfUser: PropTypes.func.isRequired,
    toggleNewUserModal: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        waitingToSignByOthers: state.containerList.waitingToSignByOthers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleNewUserModal: (onUserCreatedEvent) => {
            dispatch(toggleModal(CREATE_NEW_USER, { onUserCreated: onUserCreatedEvent }));
        },
        getUsers: (filter, orderBy, order, userLimit, userOffset, onlyAdmins) => {
            return dispatch(getUsers(filter, orderBy, order, userLimit, userOffset, onlyAdmins));
        },
        editUser: (selectedUserEmail) => {
            getHistory().push(`/user/${selectedUserEmail}`);
        },
        deleteUser: (email) => {
            return dispatch(deleteUser(email));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

const getColumns = (translate, onDeleteUsers, editUser, showFileListOfUser, handleTokenDelete, handleSecondFactor) => [
    {
        title: translate('adminPage.users.email'),
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: translate('adminPage.users.userType'),
        align: 'center',
        dataIndex: 'userType',
        key: 'userType',
    },
    {
        title: translate('adminPage.users.credentialType'),
        align: 'center',
        dataIndex: 'credentialType',
        key: 'credentialType',
    },
    {
        title: translate('adminPage.users.setPassword'),
        align: 'center',
        dataIndex: 'password',
        key: 'password',
    },
    {
        title: translate('adminPage.users.oid'),
        align: 'center',
        dataIndex: 'oid',
        key: 'oid',
    },
    {
        title: translate('adminPage.users.tsUser'),
        align: 'center',
        dataIndex: 'tsUser',
        key: 'tsUser',
    },
    {
        title: translate('adminPage.users.created'),
        align: 'center',
        dataIndex: 'created',
        key: 'created',
        sorter: (a, b) => {
            return a.created.localeCompare(b.created);
        },
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: translate('adminPage.users.language'),
        align: 'center',
        dataIndex: 'language',
        key: 'language',
    },
    {
        title: translate('adminPage.users.profile'),
        align: 'center',
        dataIndex: 'profile',
        key: 'profile',
    },
    {
        title: translate(`container.list.${TAB_KEY_DEFAULT_CONTAINERS}.title`),
        align: 'center',
        key: 'files',
        render: (text, record) => (
            <Button
                icon="file-search"
                onClick={() => showFileListOfUser(record.email)}
                title={translate('adminPage.menu.file')}
            >
                {translate(`container.list.${TAB_KEY_DEFAULT_CONTAINERS}.title`)}
            </Button>
        ),
    },
    {
        title: translate('adminPage.common.actions'),
        align: 'center',
        key: 'edit',
        render: (text, record) => (
            <Button.Group style={{ minWidth: '80px' }}>
                <Button
                    type={'default'}
                    key={'edit'}
                    onClick={() => editUser(record.email)}
                    icon={'edit'}
                    title={translate('adminPage.users.edit')}
                />
                <DeleteButton
                    key={'delete'}
                    onClick={() =>
                        confirmationModal(
                            translate,
                            () => onDeleteUsers([record.key]),
                            translate('confirm.userDeleteConfirm')
                        )
                    }
                    title={translate('adminPage.users.delete')}
                />
            </Button.Group>
        ),
    },
    {
        title: translate('adminPage.users.confirmUser'),
        align: 'center',
        key: 'token',
        dataIndex: 'token',
        render: (text, record) => (
            <Tooltip
                placement="topLeft"
                title={
                    record.token ? translate('adminPage.users.confirm') : translate('adminPage.users.alreadyConfirmed')
                }
            >
                <Button
                    icon="check-circle"
                    disabled={!record.token}
                    style={{
                        color: record.token ? theme.successColor : theme.buttonDisabledTextColor,
                        borderColor: theme.buttonDisabledTextColor,
                    }}
                    onClick={() => handleTokenDelete(record.email)}
                ></Button>
            </Tooltip>
        ),
    },
    {
        title: translate('modals.secondFactor.modalTitle'),
        align: 'center',
        key: 'secondFactor',
        dataIndex: 'secondFactor',
        render: (text, record) => (
            <Tooltip placement='topLeft'
                     title={window.config.REACT_APP_SECOND_FACTOR_MODE === 'OFF' ? translate('adminPage.common.noSecondFactor') : !record.oid && !record.secondFactor? '' : record.secondFactor ? translate('adminPage.users.unsetSecondFactor') : translate('adminPage.users.setSecondFactor')}>
                <Button
                    icon={record.secondFactor ? 'close-circle' : 'check-circle'}
                    disabled={!record.oid && !record.secondFactor}
                    style={{
                        color: record.secondFactor ? theme.errorColor : theme.successColor,
                    }}
                    onClick={() => handleSecondFactor(record.email, !record.secondFactor)}
                ></Button>
            </Tooltip>
        ),
    },
];
