import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';
import { createNotification, createNotificationShort } from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import { deleteContainer, updateSelectedContainerList } from '../../../actions/container';
import { removeModal } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';

function DeleteContainerModal(props) {
    const { newActiveContainer, onFinished, selectedContainerIDs } = props.modalData;

    const translate = useSelector((state) => getTranslate(state.locale));

    const dispatch = useDispatch();
    return (
        <ModalWrapper
            {...props}
            title={<b>{translate('modals.deleteContainer.title')}</b>}>
            <p>{translate('modals.deleteContainer.description')}</p>
            <Button
                block={true}
                id={'refuse-signature-button'}
                onClick={() => {
                    selectedContainerIDs.forEach((id) => {
                        dispatch(deleteContainer(id))
                            .then(() => {
                                newActiveContainer && dispatch(updateSelectedContainerList(newActiveContainer));
                                onFinished && onFinished();
                                createNotificationShort(translate, {
                                    message: translate('notifications.deleteContainer.success'),
                                    type: 'success'
                                });
                                dispatch(removeModal());
                            })
                            .catch((e) => {
                                createNotification(translate, e.response.data.code, {
                                    message: translate('notifications.deleteContainer.failure'),
                                    type: 'error'
                                });
                            });
                    });
                }}
                type={'primary'}>
                {translate('modals.deleteContainer.button')}
            </Button>
        </ModalWrapper>
    );
}

DeleteContainerModal.propTypes = {
    modalData: PropTypes.shape({
        newActiveContainer: PropTypes.array.isRequired,
        onFinished: PropTypes.func,
        selectedContainerIDs: PropTypes.array.isRequired
    })
};

export default DeleteContainerModal;
