import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Select } from 'antd';
import { PLACEHOLDER_CEGJEGYZEKSZAM } from '../../constants/config';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { validateCegjegyzekSzam } from '../../helper';

export default function CegeljarasInput(props) {
    const { form } = props;

    const [companyForm, setCompanyForm] = useState('VALLALAT');
    const [requestType, setRequestType] = useState('BEJEGYZESI_KERELEM');

    const translate = useSelector((state) => getTranslate(state.locale));

    return (
        <div>
            <Form.Item label={translate('modals.createContainer.label.requestType')}>
                {form.getFieldDecorator('requestType', {
                    initialValue: requestType,
                    rules: [{ message: translate('form.required'), required: true }]
                })(
                    <Select onChange={(value) => setRequestType(value)}>
                        <Select.Option value={'BEJEGYZESI_KERELEM'}>Bejegyzési kérelem</Select.Option>
                        <Select.Option value={'ISMETELT_BEJEGYZESI_KERELEM'}>Ismételt bejegyzési kérelem</Select.Option>
                        <Select.Option value={'VALTOZAS_BEJEGYZESI_KERELEM'}>Változás bejegyzési kérelem</Select.Option>
                        <Select.Option value={'NEVFOGLALASI_KERELEM'}>Névfoglalási kérelem</Select.Option>
                        <Select.Option value={'EGYEB_BEADVANY'}>Egyéb beadvány</Select.Option>
                        <Select.Option value={'HIANYPOTLAS'}>Hiánypótlás</Select.Option>
                    </Select>
                )}
            </Form.Item>

            {requestType === 'BEJEGYZESI_KERELEM' && (
                <div>
                    <Form.Item label={translate('modals.createContainer.label.companyForm')}>
                        {form.getFieldDecorator('companyForm', {
                            initialValue: companyForm,
                            rules: [{ message: translate('form.required'), required: true }]
                        })(
                            <Select onChange={(value) => setCompanyForm(value)}>
                                <Select.Option value={'VALLALAT'}>Vállalat</Select.Option>
                                <Select.Option value={'SZOVETKEZET'}>Szövetkezet</Select.Option>
                                <Select.Option value={'KOZKERESETI_TARSASAG'}>Közkereseti társaság</Select.Option>
                                <Select.Option value={'BETETI_TARSASAG'}>Betéti társaság</Select.Option>
                                <Select.Option value={'EGYESULES'}>Egyesülés</Select.Option>
                                <Select.Option value={'KOZOS_VALLALAT'}>Közös vállalat</Select.Option>
                                <Select.Option value={'KORLATOLT_FELELOSSEGU_TARSASAG'}>
                                    Korlátolt felelősségű társaság
                                </Select.Option>
                                <Select.Option value={'RESZVENYTARSASAG'}>Részvénytársaság</Select.Option>
                                <Select.Option value={'EGYENI_CEG'}>Egyéni cég</Select.Option>
                                <Select.Option value={'KULFOLDIEK_MAGYARORSZAGI_KOZVETLEN_KERESKEDELMI_KEPVISELETE'}>
                                    Külföldiek magyarországi közvetlen kereskedelmi képviselete
                                </Select.Option>
                                <Select.Option value={'OKTATOI_MUNKAKOZOSSEG'}>Oktatói munkaközösség</Select.Option>
                                <Select.Option value={'KOZHASZNU_TARSASAG'}>Közhasznú társaság</Select.Option>
                                <Select.Option value={'ERDOBIRTOKOSSAGI_TARSULAT'}>
                                    Erdőbirtokossági társulat
                                </Select.Option>
                                <Select.Option value={'VIZGAZDALKODASI_TARSULAT'}>
                                    Vízgazdálkodási társulat
                                </Select.Option>
                                <Select.Option value={'KULFOLDI_VALLALKOZAS_MAGYARORSZAGI_FIOKTELEPE'}>
                                    Külföldi vállalkozás magyarországi fióktelepe
                                </Select.Option>
                                <Select.Option value={'VEGREHAJTOI_IRODA'}>Végrehajtói iroda</Select.Option>
                                <Select.Option value={'EUROPAI_GAZDASAGI_EGYESULES'}>
                                    Európai gazdasági egyesülés
                                </Select.Option>
                                <Select.Option value={'EUROPAI_RESZVENYTARSASAG'}>
                                    Európai részvénytársaság
                                </Select.Option>
                                <Select.Option value={'KOZJEGYZOI_IRODA'}>Közjegyzői iroda</Select.Option>
                                <Select.Option
                                    value={'KULFOLDI_SZEKHELYU_EUROPAI_GAZDASAGI_EGYESULES_MAGYARORSZAGI_TELEPHELYE'}>
                                    Külföldi székhelyű európai gazdasági egyesülés magyarországi telephelye
                                </Select.Option>
                                <Select.Option value={'EUROPAI_SZOVETKEZET'}>Európai szövetkezet</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </div>
            )}

            {['ISMETELT_BEJEGYZESI_KERELEM', 'VALTOZAS_BEJEGYZESI_KERELEM', 'EGYEB_BEADVANY', 'HIANYPOTLAS'].includes(
                requestType
            ) && (
                <div>
                    <Form.Item
                        hasFeedback={true}
                        label={translate('modals.createContainer.label.cegjegyzekszam')}>
                        {form.getFieldDecorator('cegjegyzekszam', {
                            rules: [
                                { message: translate('form.required'), required: true },
                                {
                                    validator: (rule, value, callback) =>
                                        validateCegjegyzekSzam(value, callback, translate)
                                }
                            ],
                            validateTrigger: ['onBlur', 'onChange']
                        })(
                            <Cleave
                                className={'ant-input'}
                                options={{ blocks: [2, 2, 6], delimiter: '-', numericOnly: true }}
                                placeholder={PLACEHOLDER_CEGJEGYZEKSZAM}
                            />
                        )}
                    </Form.Item>
                </div>
            )}

            <Form.Item
                hasFeedback={true}
                label={translate('modals.createContainer.label.companyName')}>
                {form.getFieldDecorator('companyName', {
                    rules: [{ required: true, message: translate('form.required') }],
                    validateTrigger: ['onBlur', 'onChange']
                })(<Input />)}
            </Form.Item>

            {[
                'BEJEGYZESI_KERELEM',
                'ISMETELT_BEJEGYZESI_KERELEM',
                'VALTOZAS_BEJEGYZESI_KERELEM',
                'EGYEB_BEADVANY',
                'HIANYPOTLAS'
            ].includes(requestType) && (
                <Form.Item
                    hasFeedback={true}
                    label={translate('modals.createContainer.label.companySeat')}>
                    {form.getFieldDecorator('companySeat', {
                        rules: [{ message: translate('form.required'), required: true }],
                        validateTrigger: ['onBlur', 'onChange']
                    })(<Input />)}
                </Form.Item>
            )}
        </div>
    );
}

CegeljarasInput.propTypes = {
    form: PropTypes.object.isRequired
};
