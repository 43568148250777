import authService from '../services/authService';
import { ajax } from '../helper';
import { fetchAllSignatureProfiles } from './signatureProfile';
import { resetContainerList } from './container';
import { resetDocumentList } from './document';

export function login(post) {
    return function (dispatch) {
        return ajax(true)
            .post('/login', post)
            .then((response) => {
                authService.logout();
                const { data } = response;
                if (response.status === 202) {
                    return response;
                }
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile, dispatch);
                authService.setSignMode(data.userSignMode);
                authService.setNotificationModes(data.pushNotification, data.emailNotification);
                authService.setSigningWithTimestampOnly(data.signingWithTimestampOnly);
                authService.setSecondFactor(data.secondFactor);
                authService.setSignatureSetting(data.signatureSetting);
                if (data.registrationType === 'pwd_hash') {
                    authService.setRegistrationType('password');
                } else if (data.registrationType === 'microsec_oid') {
                    authService.setRegistrationType('oid');
                }
                authService.setUserOid(data.userOid);
                dispatch(fetchAllSignatureProfiles());
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function startMobileLogin(friendlyName) {
    return function () {
        return ajax()
            .get(`/startMobileLogin?friendlyName=${friendlyName}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function pollingMobileLogin(modalData, lang, isLogin) {
    return function (dispatch) {
        return ajax()
            .post('/pollingMobileLogin', {
                token: modalData.token,
                pollingTimeIntervalSec: modalData.pollingTimeIntervalSec,
                lang: lang,
                jwt: modalData.jwt,
                isLogin: modalData.isLogin
            })
            .then((response) => {
                if (response.status === 202) {
                    return 202;
                }
                const { data } = response;
                if (data.JWT === undefined) {
                    return { data: { notifyUser: false } };
                }
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile);
                authService.setSignMode(data.userSignMode);
                authService.setNotificationModes(data.pushNotification, data.emailNotification);
                authService.setSigningWithTimestampOnly(data.signingWithTimestampOnly);
                authService.setSecondFactor(data.secondFactor);
                if (data.registrationType === 'pwd_hash') {
                    authService.setRegistrationType('password');
                } else if (data.registrationType === 'microsec_oid') {
                    authService.setRegistrationType('oid');
                }
                authService.setUserOid(data.userOid);
                dispatch(fetchAllSignatureProfiles());
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function pollingSecondFactor(data, lang) {
    return function (dispatch) {
        return ajax()
            .post('/pollingSecondFactor', {
                jwt: data.jwt,
                lang: lang
            })
            .then((response) => {
                if (response.status === 202) {
                    return 202;
                }
                const { data } = response;
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile);
                authService.setSignMode(data.userSignMode);
                authService.setNotificationModes(data.pushNotification, data.emailNotification);
                authService.setSigningWithTimestampOnly(data.signingWithTimestampOnly);
                authService.setSecondFactor(data.secondFactor);
                if (data.registrationType === 'pwd_hash') {
                    authService.setRegistrationType('password');
                } else if (data.registrationType === 'microsec_oid') {
                    authService.setRegistrationType('oid');
                }
                authService.setUserOid(data.userOid);
                dispatch(fetchAllSignatureProfiles());
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function pollingUpdateSecondFactor(data) {
    return function (dispatch) {
        return ajax()
            .post('/pollingUpdateSecondFactor', {
                messageId: data.messageId,
                secondFactor: data.secondFactor
            })
            .then((response) => {
                if (response.status === 202) {
                    return 202;
                }
                authService.setSecondFactor(response.data.secondFactor);
                return 200;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function pollingTokenProvider(modalData, lang) {
    return function (dispatch) {
        return ajax()
            .post('/pollingTokenProvider', {
                token: modalData.token,
                pollingTimeIntervalSec: modalData.pollingTimeIntervalSec,
                lang: lang,
                isLogin: modalData.isLogin
            })
            .then((response) => {
                if (response.status === 202) {
                    return 202;
                }
                authService.setSecondFactor(response.data.secondFactor);
                return 200;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function adminLogin(post) {
    return function (dispatch) {
        return ajax()
            .post('/admin/login', post)
            .then((response) => {
                authService.logout();
                const { data } = response;
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile, dispatch);
                authService.setPasswordEnabled(data.setPasswordEnabled);
                authService.adminLogin();
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function authWithSocialMedia(postData) {
    return function (dispatch) {
        return ajax()
            .post('/login', postData)
            .then((response) => {
                const { data } = response;
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                authService.setUserProfile(data.userProfile, dispatch);
                authService.setSignMode(data.userSignMode);
                authService.setRegistrationType('token');
                authService.setNotificationModes(data.pushNotification, data.emailNotification);
                authService.setSigningWithTimestampOnly(data.signingWithTimestampOnly);
                authService.setSecondFactor(data.secondFactor);
                authService.setUserOid(data.userOid);
                dispatch(fetchAllSignatureProfiles());
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function renewPassword(pwdToken, newPwd) {
    return function () {
        return ajax(true)
            .post('/resetPassword', { passwordToken: pwdToken, newPassword: newPwd })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function cancelNotification(jwt, type, messageId) {
    return function () {
        return ajax()
            .post(`/cancelNotification?jwt=${jwt ? jwt : ''}&messageId=${messageId ? messageId : ''}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function registration(postData) {
    return function () {
        return ajax()
            .post('/registration', postData)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function forgottenPassword(email, lang) {
    return function () {
        return ajax(true)
            .post('/forgottenPassword', { targetEmail: email, language: lang })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function changePassword(oldPwd, newPwd) {
    return function () {
        return ajax()
            .post('/changePassword', {
                oldPassword: oldPwd,
                newPassword: newPwd
            })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function setADPassword(password) {
    return function () {
        return ajax()
            .put('/setADPassword', {
                password: password,
            })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function clearTsaCredentials() {
    return function () {
        return ajax()
            .delete('/tsp')
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function clearRecentEmails() {
    return function () {
        return ajax()
            .delete('/recentEmails')
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function setDeputy(deputyEmail, deputyPeriod) {
    return function () {
        return ajax()
            .post('/deputy', {
                deputyEmail,
                deputyPeriod
            })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function checkDeputy() {
    return function () {
        return ajax()
            .get('/deputy')
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function deleteUser() {
    return function () {
        return ajax()
            .delete('/registration')
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function resendConfirmation(email) {
    return function () {
        return ajax()
            .post('/resendConfirmation', { targetEmail: email })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function logout() {
    return function (dispatch) {
        return ajax()
            .post('/logout')
            .then((response) => {
                dispatch(resetContainerList());
                dispatch(resetDocumentList());
                authService.logout();
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function stopNotifyUser() {
    return function () {
        ajax()
            .delete('/notifyUser')
            .catch((err) => {
                throw err;
            });
    };
}

export function changeLanguage(language) {
    return function () {
        return ajax()
            .get(`/changeLanguage/${language.languageCode}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function updateUserSignMode(signMode) {
    return function () {
        return ajax()
            .put(`/signMode`, { userSignMode: signMode })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function updateSignatureSetting(signatureSetting) {
    return function () {
        return ajax()
            .post(`/signatureSetting`, { userSignatureSetting: signatureSetting })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function getAttributeRoles(certificate) {
    return function () {
        return ajax()
            .post('/attributeRoles', { certificate: certificate })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function getAttributeUsage() {
    return function () {
        return ajax()
            .get('/attributeCertificate')
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function resetSecondFactorIntent(email, lang) {
    return function () {
        return ajax()
            .post('/resetSecondFactorIntent', { targetEmail: email, language: lang })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}
