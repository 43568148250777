import * as types from './actionTypes';
import {ajax} from '../helper';
import {getContainerDocuments} from './document';

export function resetContainerList() {
    return {
        type: types.RESET_CONTAINER_LIST,
    };
}

function parseContainerResponse(responseData, action) {
    let parsedResponse = {};
    let containers = [];
    responseData.containerResponseList.forEach((c) => {
        containers.push({
            data: {
                creationDate: c.creationDate,
                fileBaseName: c.title,
                id: c.id,
                signedFromBulkSigning: c.signedFromBulkSigning,
                waitingToSignByOthers: c.waitingToSignByOthers,
                waitingToSign: c.waitingToSign,
                type: c.type,
                esNamespace: c.esNamespace,
                wasOpened: c.wasOpened,
                size: c.size.toString(),
                mimeType: c.mimeType,
                pages: c.pages,
                pdfEmbeddedFiles: c.pdfEmbeddedFiles,
                error: c.error,
                pdfFormFields: c.pdfFormFields,
                processStarter: c.processStarter,
                workflowGuid: c.workflowGuid,
                isCounterSignable: c.isCounterSignable,
                action: action === 'none' ? 'none' : c.signedFromBulkSigning === 'NOT_SIGNED' ? 'sign' : 'approve'
            }
        });
    });
    parsedResponse.containers = containers;
    parsedResponse.allContainerCount = responseData.allContainerCount;
    parsedResponse.containerLimit = responseData.containerLimit;
    parsedResponse.containerOffset = responseData.containerOffset;
    return parsedResponse;
}

export function parseOneContainerResponse(responseData, action) {
    let container;
    container = {
        data: {
            creationDate: responseData.creationDate,
            fileBaseName: responseData.title,
            id: responseData.id,
            signedFromBulkSigning: responseData.signedFromBulkSigning,
            waitingToSignByOthers: responseData.waitingToSignByOthers,
            waitingToSign: responseData.waitingToSign,
            type: responseData.type,
            esNamespace: responseData.esNamespace,
            wasOpened: responseData.wasOpened,
            size: responseData.size ? responseData.size.toString() : '',
            mimeType: responseData.mimeType,
            pages: responseData.pages,
            pdfEmbeddedFiles: responseData.pdfEmbeddedFiles,
            error: responseData.error,
            pdfFormFields: responseData.pdfFormFields,
            processStarter: responseData.processStarter,
            workflowGuid: responseData.workflowGuid,
            isCounterSignable: responseData.isCounterSignable,
            action:
                action === 'none' ? 'none' : responseData.signedFromBulkSigning === 'NOT_SIGNED' ? 'sign' : 'approve'
        }
    };
    return container;
}

function parseContainerFilterParams(requestOptions) {
    let filter = requestOptions && requestOptions.filter ? requestOptions.filter : '' ;
    let orderBy;
    let order = requestOptions && requestOptions.order ? requestOptions.order :'DESC' ;
    let containerLimit = requestOptions && requestOptions.limit ? requestOptions.limit: '10';
    let containerOffset = requestOptions && requestOptions.offset ? requestOptions.offset: '0';

    if (!requestOptions || !requestOptions.orderBy) {
        orderBy = 'created';
    } else {
        if (requestOptions && requestOptions.orderBy === 'name') {
            orderBy = 'file_name';
        } else if (requestOptions.orderBy === 'createdBy') {
            orderBy = 'created_by_email';
        } else {
            orderBy = 'created';
        }
    }
    return `filter=${filter}&orderBy=${orderBy}&order=${order}&containerLimit=${containerLimit}&containerOffset=${containerOffset}`;
}

export function getContainers(requestOptions) {
    return function (dispatch) {
        const filterParams = parseContainerFilterParams(requestOptions);
        return ajax()
            .get(`/containers/filtered?compat=true&${filterParams}`)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseContainerResponse(data, 'none');
                parsedResponse.filtered = requestOptions && requestOptions.filter !== undefined && requestOptions.filter.length > 0;
                if ((parsedResponse.allContainerCount === 0) || (parsedResponse.allContainerCount > parsedResponse.containerOffset)) {
                    dispatch(getContainersSuccess(parsedResponse));
                } else {
                    requestOptions.offset -= requestOptions.limit;
                    requestOptions.offset = Math.max(0, requestOptions.offset);
                    dispatch(getContainers(requestOptions));
                }
                return parsedResponse;
            })
            .catch((error) => {
                dispatch(getContainersFailure());
                throw error;
            });
    };
}

export function getContainersInSignatureProcess(filter, orderBy, order, containerLimit, containerOffset) {
    return function (dispatch) {
        const filterParams = parseContainerFilterParams(filter, orderBy, order, containerLimit, containerOffset);
        return ajax()
            .get(`/containers/filtered?status=waiting_to_sign;waiting_to_approve&compat=true&${filterParams}`)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseContainerResponse(data);
                dispatch(getContainersInSignatureProcessSuccess(parsedResponse.containers));
                return parsedResponse;
            })
            .catch((error) => {
                dispatch(getContainersInSignatureProcessFailure());
                throw error;
            });
    };
}

export function getContainersSuccess(parsedResponse) {
    return {
        type: types.GET_CONTAINERS_SUCCESS,
        payload: parsedResponse,
    };
}

export function getContainersFailure() {
    return {
        type: types.GET_CONTAINERS_FAILURE,
    };
}

export const storeFileId = (id) => {
    return {
        type: types.STORE_FILE_ID,
        payload: id,
    };
};

export const deleteStoredFileId = () => {
    return {
        type: types.DELETE_STORED_FILE_ID,
    };
};

export function getContainerSignaturesSuccess(signatures) {
    return {
        type: types.GET_CONTAINER_SIGNATURES_SUCCESS,
        payload: signatures,
    };
}

export function getContainerSignaturesFailure() {
    return {
        type: types.GET_CONTAINER_SIGNATURES_FAILURE,
    };
}

export function getContainerSignatures(uriType, id) {
    return function (dispatch) {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                let signaturesData =
                    response.data.details != null
                        ? response.data.details.signatures != null
                            ? response.data.details.signatures
                            : []
                        : [];
                if (response.data.type && response.data.type === 'asic') {
                    dispatch(getContainerDocuments(response.data.details.documents, id));
                }
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            })
            .catch((error) => {
                dispatch(getContainerSignaturesFailure());
                throw error;
            });
    };
}

export function deleteContainerSignature(signatureId) {
    return {
        type: types.DELETE_SIGNATURE_SUCCESS,
        payload: signatureId,
    };
}

export function deleteContainer(id) {
    return function (dispatch) {
        return ajax()
            .delete(`/containers/${id}`)
            .then((response) => {
                dispatch(deleteContainerSuccess(id));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function deleteContainerSuccess(containerId) {
    return {
        type: types.DELETE_CONTAINER_SUCCESS,
        payload: containerId,
    };
}

export const newContainerSuccess = (data) => {
    return {
        type: types.NEW_CONTAINER_SUCCESS,
        payload: data,
    };
};

export const uploadContainerSuccess = (data) => {
    return {
        type: types.UPLOAD_CONTAINER_SUCCESS,
        payload: data,
    };
};

export const containerRenameSuccess = (index, newTitle) => {
    return {
        type: types.RENAME_CONTAINER_SUCCESS,
        index: index,
        title: newTitle,
    };
};

export const validateSignatureFailure = (selectedSignature) => {
    return {
        type: types.VALIDATE_SIGNATURE_FAILURE,
        payload: {
            sig: selectedSignature,
        },
    };
};

export const validateSignatureSuccess = (selectedSignature) => {
    return {
        type: types.VALIDATE_SIGNATURE_SUCCESS,
        payload: selectedSignature,
    };
};

export const setArchiveSignatureSuccess = (newSignature) => {
    return {
        type: types.ARCHIVE_SIGNATURE_SUCCESS,
        payload: newSignature,
    };
};

export const sendInEmail = (id, email, lang) => {
    return function () {
        return ajax()
            .post(`/containers/${id}/send`, { targetEmails: [`${email}`], language: lang })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const SendForSignature = (ids, targets, ack, comment, metadata) => {
    return function () {
        return ajax()
            .post(`/containers/bulkSigningProcesses`, {
                targets: targets,
                acknowledgementIsRequired: ack,
                containerIds: ids,
                comment: comment,
                metadata: metadata,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export function searchEmails(emailPiece) {
    return function () {
        return ajax()
            .get(`/emailAddress/search?emailPiece=${emailPiece}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const renameContainer = (id, title) => {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${id}/rename`, { title: title })
            .then((response) => {
                dispatch(containerRenameSuccess(id, title));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
};

export function validateSignature(containerId, signature, refContainer) {
    return function (dispatch) {
        if (signature === null) {
            return ajax()
                .post(`/containers/${containerId}/signatures/nop/validate`, { referenceContainerId: refContainer })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        } else if (refContainer === null) {
            return ajax()
                .post(`/containers/${containerId}/signatures/${signature.id}/validate`, { referenceContainerId: '' })
                .then((response) => {
                    dispatch(validateSignatureSuccess(signature));
                    return response;
                })
                .catch((error) => {
                    dispatch(validateSignatureFailure(signature));
                    throw error;
                });
        }
    };
}

export function createArchiveSignature(containerId, signatureId) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${containerId}/signatures/${signatureId}/addArchiveTimestamp`, {
                referenceContainerId: '',
            })
            .then(function (response) {
                let signaturesData =
                    response.data.details != null
                        ? response.data.details.signatures != null
                            ? response.data.details.signatures
                            : []
                        : [];
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function createContainer(json, isCegeljaras) {
    return function (dispatch) {
        let url = '/containers';
        if (isCegeljaras) {
            url = '/containers/ecegeljaras';
        }
        return ajax()
            .post(url, json)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseOneContainerResponse(data, 'none');
                dispatch(newContainerSuccess(parsedResponse));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function uploadContainer(formData) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/upload`, formData)
            .then((response) => {
                const data = response.data;
                const parsedResponse = parseOneContainerResponse(data, 'none');
                dispatch(uploadContainerSuccess(parsedResponse));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function deleteSignature(contId, signatureId) {
    return function (dispatch) {
        return ajax()
            .delete(`/containers/${contId}/signatures/${signatureId}`)
            .then((response) => {
                const data = response.data;
                let signaturesData =
                    data.details != null ? (data.details.signatures != null ? data.details.signatures : []) : [];
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function validateAcknowledgement(id, param) {
    return function () {
        return ajax()
            .post(`/eacknowledgement/${id}/validate${param}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const newContainer = (id) => {
    return function (dispatch) {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                dispatch(
                    newContainerSuccess({
                        data: {
                            creationDate: response.data.creationDate,
                            fileBaseName: response.data.title,
                            id: response.data.id,
                            signedFromBulkSigning: response.data.signedFromBulkSigning,
                            waitingToSign: response.data.waitingToSign,
                            type: response.data.type,
                            wasOpened: response.data.wasOpened,
                            size: response.data.size.toString(),
                            mimeType: response.data.mimeType,
                            pages: response.data.pages,
                            pdfEmbeddedFiles: response.data.pdfEmbeddedFiles,
                            error: response.data.error,
                            pdfFormFields: response.data.pdfFormFields,
                            processStarter: response.processStarter,
                            workflowGuid: response.workflowGuid,
                            isCounterSignable: response.data.isCounterSignable,
                        },
                    })
                );
                dispatch(updateSelectedContainerList([response]));
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const getContainerData = (id) => {
    return function () {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                return {
                    data: {
                        creationDate: response.data.creationDate,
                        fileBaseName: response.data.title,
                        id: response.data.id,
                        signedFromBulkSigning: response.data.signedFromBulkSigning,
                        waitingToSign: response.data.waitingToSign,
                        type: response.data.type,
                        esNamespace: response.data.esNamespace,
                        wasOpened: response.data.wasOpened,
                        size: response.data.size.toString(),
                        mimeType: response.data.mimeType,
                        pages: response.data.pages,
                        pdfEmbeddedFiles: response.data.pdfEmbeddedFiles,
                        error: response.data.error,
                        pdfFormFields: response.data.pdfFormFields,
                        processStarter: response.processStarter,
                        workflowGuid: response.workflowGuid,
                        isCounterSignable: response.data.isCounterSignable,
                    }
                };
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const updateContainerData = (id, noAction) => {
    return function (dispatch) {
        if (!id) {
            return Promise.resolve(null);
        }
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((containerResponse) => {
                const uriType = containerResponse.type;
                if (['edossier', 'asic', 'eacknowledgement'].includes(uriType)) {
                    dispatch(getContainerDocuments(containerResponse.details.documents, id));
                }
                return containerResponse;
            })
            .then((containerResponse) => {
                let signaturesData =
                    containerResponse.details != null
                        ? containerResponse.details.signatures != null
                            ? containerResponse.details.signatures
                            : []
                        : [];
                dispatch(getContainerSignaturesSuccess(signaturesData));
                return noAction ? parseOneContainerResponse(containerResponse, 'none').data : parseOneContainerResponse(containerResponse).data;
            })
            .catch((error) => {
                dispatch(getContainerSignaturesFailure());
                throw error;
            });
    };
};

export const updateSelectedContainerList = (containerList) => {
    return {
        type: types.UPDATE_SELECTED_CONTAINER_IDS,
        payload: containerList
    };
};

export const resetSelectedContainers = () => {
    return {
        type: types.RESET_SELECTED_CONTAINER_IDS,
    };
};

export const setSelectedContainers = (containers) => {
    return {
        type: types.SET_SELECTED_CONTAINERS,
        payload: containers
    };
};

export const selectAllContainer = (containerList) => {
    return {
        type: types.SELECT_ALL_CONTAINER,
        payload: containerList
    };
};

export const getPdfPreview = (id, pageNumber) => {
    return function () {
        return ajax()
            .get(`/containers/${id}/preview?pageNumber=${pageNumber}`)
            .then((data) => {
                return `data:image/jpeg;base64,${data.data}`;
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const refuseSigning = (refusedElements) => {
    return function () {
        return ajax()
            .post(`/containers/refuseSigning`, { refusedElements: refusedElements })
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const getWaitingToSignByOthers = () => {
    return function (dispatch) {
        return ajax()
            .get('/containers/bulkSigningProcesses')
            .then((response) => {
                dispatch(updateWaitingToSignByOthers(response.data));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const getWaitingToSignByOthersByContainer = (guid) => {
    return function (dispatch) {
        return ajax()
            .get(`/containers/bulkSigningProcesses/${guid}`)
            .then((response) => {
                dispatch(updateWaitingToSignByOthersByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const approveContainers = (containerIds) => {
    return function () {
        return ajax()
            .post('/containers/approve', { containers: containerIds })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export function getContainersInSignatureProcessSuccess(containers) {
    return {
        type: types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_SUCCESS,
        payload: containers
    };
}

export function getContainersInSignatureProcessFailure() {
    return {
        type: types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_FAILURE
    };
}

export const updateWaitingToSignByOthers = (containers) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS,
        payload: containers,
    };
};

export const updateWaitingToSignByOthersByContainer = (container) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER,
        payload: container,
    };
};

export const sortContainerListByDateAsc = () => {
    return {
        type: types.SORT_CONTAINER_LIST_BY_DATE_ASC,
    };
};

export const sortContainerListByDateDesc = () => {
    return {
        type: types.SORT_CONTAINER_LIST_BY_DATE_DESC,
    };
};

export const sortContainerListByNameAsc = () => {
    return {
        type: types.SORT_CONTAINER_LIST_BY_NAME_ASC,
    };
};

export const sortContainerListByNameDesc = () => {
    return {
        type: types.SORT_CONTAINER_LIST_BY_NAME_DESC,
    };
};
