/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import {
    deleteSignatureProfile,
    fetchAllSignatureProfiles,
    toggleDefaultSignatureProfile
} from '../actions/signatureProfile';
import { toggleModal } from '../actions/modal';
import {
    ajax,
    createNotification,
    createNotificationShort, disabledRangeTime, getDocuments, getHistory,
    getSignatureSetting, getSignModeForRequest,
    getSignModeLocalStorage, hasAnyExtraFeatureInPackage, setSignModeLocalStorage
} from '../helper';
import authService from '../services/authService';
import { browserName, osName, osVersion } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import {
    Alert,
    Avatar,
    Button,
    Checkbox,
    Col,
    Comment,
    DatePicker,
    Form,
    Icon,
    Input,
    Popover,
    Radio,
    Row,
    Table
} from 'antd';
import moment from 'moment';
import WebPage from './WebPage';
import { Link } from 'react-router-dom';
import Box from './Box';
import microSignerImg from '../images/microsigner.svg';
import eszignoImg from '../images/eszigno-full.svg';
import pbmImg from '../images/passbyme_v2.svg';
import UserCertificateView from './settings/UserCertificateView';
import eszignoTBadge from '../images/sigt.png';
import eszignoBadge from '../images/sig.png';
import FormItem from './form/FormItem';
import SettingsOid from './SettingsOid';
import { isEmpty } from 'lodash';
import DescriptionItem from './DescriptionItem';
import theme from '../theme';
import { checkTsp, tspClearSuccess } from '../actions/tsa';
import { UPDATE_MENU_MARKUP } from '../actions/actionTypes';
import {
    CREATE_SIGNATURE_SCHEMA,
    MOBILE_LOGIN,
    SECOND_FACTOR,
    UPDATE_SIGNATURE_SCHEMA,
    USER_PROFILE_ERROR
} from '../constants/modalNames';
import {
    changePassword,
    clearTsaCredentials,
    deleteUser, setADPassword,
    updateSignatureSetting,
    updateUserSignMode
} from '../actions/user';
import { deleteDeputy, getDeputy } from '../actions/deputy';
import SignatureImageInfo from './SignatureImageInfo';
import {
    addSignatureProfileImage,
    deleteSignatureProfileImage,
} from '../actions/signatureProfileImage';

const Settings = (props) => {
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [checked, setChecked] = useState(true);
    const [changed, setChanged] = useState(true);
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [schemaTablePage, setSchemaTablePage] = useState(1);
    const [signatureSetting, setSignatureSetting] = useState(getSignatureSetting());
    const [signMode, setSignMode] = useState(getSignModeLocalStorage() ? getSignModeLocalStorage() : 'sign');
    const translate = useSelector((state) => getTranslate(state.locale));
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);
    const tsa = useSelector((state) => state.tsa);
    const deputy = useSelector((state) => state.deputy);
    const signatureProfiles = useSelector((state) => state.signatureProfiles);
    const signatureProfileImages = useSelector((state) => state.signatureProfileImages);

    const textInput = useRef();
    const oidRef = useRef();

    useEffect(() => {
        setIsMounted(true);
        if (authService.isRegistered() && window.config.REACT_APP_TIMESTAMP_LOGIN_MODE !== 'microsec') {
            dispatch(checkTsp())
                .catch((err) => {
                    serverError(err);
                });
        }
        if (props.history.location.focusOnOid) {
            oidRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            textInput.current.focus();
        }
        updateSignatureProfiles();
        secondFactorElementChanged();
        return () => {
            setIsMounted(false);
        };
    }, []);

    //FIXME: Springboot-os verzióban milyen jó lenne, ha lejönnének a képek is amikor lekérjük az összes profilt, így nem kéne egyesével lekérni... :)
    const updateSignatureProfiles = () => {
        dispatch(fetchAllSignatureProfiles()).then((res) => {
            res.data.forEach((profile) => {
                if (profile.id !== 2) {
                    // 2 = empty profile
                    ajax()
                        .get(`/signatureImage/${profile.id}`)
                        .then((response) => {
                            dispatch(addSignatureProfileImage({ id: profile.id, image: response.data.image }));
                        })
                        .catch((e) => {
                            createNotification(translate, e.response.data.code, {
                                message: translate('messages.error.schemaUpdate'),
                                type: 'error'
                            });
                        });
                }
            });
        });
    };

    const secondFactorElementChanged = () => {
        isMounted && setChanged(!changed);
    };

    const handleConfirmBlur = (e) => {
        const { value } = e.target;
        isMounted && setConfirmDirty(confirmDirty || !!value);
    };

    const validateToNextPassword = (rule, value, callback) => {
        const { form } = props;

        if (value && confirmDirty) {
            form.validateFields(['newPasswordAgain'], { force: true });
        }
        callback();
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const { form } = props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback(translate('form.password.different'));
        } else {
            callback();
        }
    };

    const changePasswordAction = (values) => {
        return dispatch(changePassword(values.currentPassword, values.newPassword))
            .then(() => {
                createNotificationShort(translate, {
                    message: translate('changePassword.operation.success.message'),
                    type: 'success'
                });
            })
            .catch((error) => {
                return Promise.reject(error.response.data);
            });
    };

    const clearTsaCredentialsAction = () => {
        dispatch(clearTsaCredentials())
            .then(() => {
                dispatch(tspClearSuccess());
            }).then(() => {
            createNotificationShort(translate, {
                message: translate('messages.success.clearTsaCredentials'),
                type: 'success'
            });
        })
            .catch((e) => {
                createNotification(translate, e.response.data.code, {
                    message: translate('messages.error.clearTsaCredentials'),
                    type: 'error'
                });
            });
    };

    const deleteUserAction = () => {
        if (window.confirm(translate('texts.unregisterAlert'))) {
            dispatch(deleteUser())
                .then(() => {
                    createNotificationShort(translate, {
                        message: translate('messages.success.unregister'),
                        type: 'success'
                    });
                    props.history.push('/login');
                })
                .catch((error) => {
                    createNotification(translate, error.response.data.code, {
                        duration: 30,
                        message:
                            translate('messages.error.unregister') +
                            translate(`errorCodes.${error.response.data.code}`),
                        type: 'error'
                    });
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll(
            ['currentPassword', 'newPassword', 'newPasswordAgain'],
            (clientErr, values) => {
                if (!clientErr) {
                    changePasswordAction(values)
                        .then(() => {
                            props.form.setFieldsValue({
                                currentPassword: '',
                                newPassword: '',
                                newPasswordAgain: ''
                            });
                        })
                        .catch((err) => {
                            createNotification(translate, err.response.data.code, {
                                message: translate(`errorCodes.${err.code}`),
                                type: 'error'
                            });
                        });
                }
            }
        );
    };


    const handleSubmitSetADPassword = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll(
            ['ADPassword'],
            (clientErr, values) => {
                if (!clientErr) {
                    dispatch(setADPassword(values.ADPassword))
                        .then(() => {
                            props.form.setFieldsValue({
                                ADPassword: '',
                            });
                        })
                        .catch((err) => {
                            createNotification(this.props.translate, err.response.data.code, {
                                message: this.props.translate(`errorCodes.${err.code}`),
                                type: 'error'
                            });
                        });
                }
            }
        );
    };

    const handleSubmitNotificationMode = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll(['emailNotification', 'pushNotification'], (clientErr, values) => {
            if (!clientErr) {
                ajax()
                    .post('/notificationMode', values)
                    .then(() => {
                        authService.setNotificationModes(values.pushNotification, values.emailNotification);
                        createNotificationShort(translate, {
                            message: translate('messages.success.settingSaved'),
                            type: 'success'
                        });
                    })
                    .catch((error) => {
                        serverError(error);
                    });
            }
        });
    };

    const cancelCallback = (jwt, messageId) => {
        const checkBoxState = props.form.getFieldValue('secondFactor');
        props.form.setFieldsValue({ secondFactor: !checkBoxState });
        localStorage.setItem('secondFactor', !checkBoxState);
        props.actions.cancelNotification(jwt, messageId);
    };

    const handleSubmitSecondFactor = (e) => {
        isMounted && setButtonLoading(true);
        e.preventDefault();
        props.form.validateFieldsAndScroll(['secondFactor'], (clientErr, values) => {
            if (!clientErr) {
                ajax()
                    .put(
                        '/secondFactor',
                        Object.assign(values, { friendlyName: `${browserName}/${osName}-${osVersion}` })
                    )
                    .then((response) => {
                        if (response.status === 202) {
                            if (response.data.qrContent) {
                                dispatch(toggleModal(MOBILE_LOGIN, Object.assign(response.data, {
                                        isLogin: false,
                                        onCancel: () => cancelCallback
                                    }))
                                );
                            } else {
                                dispatch(toggleModal(SECOND_FACTOR,
                                    Object.assign(response.data, {
                                        pollType: 'settingPoll',
                                        onCancel: () =>
                                            cancelCallback(response.data.jwt, response.data.messageId)
                                    })
                                ));
                            }
                        } else {
                            createNotificationShort(translate, {
                                message: translate('messages.success.settingSaved'),
                                type: 'success'
                            });
                            localStorage.setItem('secondFactor', checked);
                        }
                    })
                    .catch((error) => {
                        serverError(error);
                        props.form.setFieldsValue({ secondFactor: false });
                    })
                    .finally(() => {
                        isMounted && setButtonLoading(false) && setChecked(true);
                    });
            }
        });
    };

    const serverError = (err) => {
        let errorCode = 'HTTP_NO_RESPONSE';
        if (err.response) {
            errorCode = err.response.data.code;
        }
        createNotification(translate, err.response.data.code, {
            message: translate(`errorCodes.${errorCode}`),
            type: 'error'
        });
    };

    const submitDeputy = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll(['deputyEmail', 'deputyPeriod'], (clientErr, values) => {
            if (!clientErr) {
                ajax()
                    .post('/deputy', {
                        userEmail: localStorage.getItem('user'),
                        deputyEmail: values.deputyEmail,
                        deputyPeriod: { from: values.deputyPeriod[0], to: values.deputyPeriod[1] }
                    })
                    .then(() => {
                        dispatch(getDeputy()).then(() => {
                            createNotificationShort(translate, {
                                message: translate('notificationDeputyText', { email: values.deputyEmail }),
                                type: 'success'
                            });
                        });
                    })
                    .catch((error) => {
                        // TODO: Check if there is a deputy for the selected period, and dont throw an error!
                        serverError(error);
                    });
            }
        });
    };

    const changeSignMode = (value) => {
        // we need to set the state of local storage in order to detect changes!!
        isMounted && setSignMode(value);
        setSignModeLocalStorage(value);
        dispatch(updateUserSignMode(getSignModeForRequest()));
    };

    const changeSignatureSetting = (value) => {
        isMounted && setSignatureSetting(value);
        setSignatureSetting(value);
        localStorage.setItem('signatureSetting', value);
        dispatch(updateSignatureSetting(value));
    };

    const createProfile = () => {
        if (localStorage.getItem('customSignatureImage') !== 'true' && !hasAnyExtraFeatureInPackage()) {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        } else {
            dispatch(toggleModal(CREATE_SIGNATURE_SCHEMA));
        }
    };

    const deleteSchema = async (profile) => {
        if (localStorage.getItem('customSignatureImage') !== 'true' && !hasAnyExtraFeatureInPackage()) {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        } else {
            await ajax()
                .delete(`/signatureProfiles/${profile.id}`)
                .then(() => {
                    dispatch(deleteSignatureProfile(profile));
                    dispatch(deleteSignatureProfileImage(profile))
                    createNotificationShort(translate, {
                        message: translate('messages.success.schemaDelete'),
                        type: 'success'
                    });
                })
                .catch((e) => {
                    createNotification(translate, e.response.data.code, {
                        message: translate('messages.error.schemaDelete'),
                        type: 'error'
                    });
                });
        }
    };

    const updateSchema = (profile) => {
        if (localStorage.getItem('customSignatureImage') !== 'true' && !hasAnyExtraFeatureInPackage()) {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        } else {
            dispatch(toggleModal(UPDATE_SIGNATURE_SCHEMA, profile));
        }
    };

    const selectSchema = (selectedIdList) => {
        const schemaId = selectedIdList[0];
        if (checkCustomSignImgUsage(schemaId)) {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        } else {
            const requestBody = {
                isDefault: true
            };
            ajax()
                .put(`/signatureProfiles/${schemaId}`, requestBody)
                .then(() => {
                    dispatch(toggleDefaultSignatureProfile(schemaId));
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    };

    const onPageChange = (page) => {
        isMounted && setSchemaTablePage(page);
    };

    const addEmptyRowsToSignatureProfiles = (profiles) => {
        if (profiles.length > 5) {
            const remainder = profiles.length % 5;
            const numberOfEmptyRows = 5 - remainder;
            let emptyRows = [];
            for (let i = 0; i < numberOfEmptyRows; i++) {
                emptyRows.push({ isEmptyRow: true, id: -1 - i });
            }
            let modified = [...profiles];
            modified = modified.concat(emptyRows);
            return modified;
        } else {
            return profiles;
        }
    };

    const checkCustomSignImgUsage = (id) => {
        return id > 2 && localStorage.getItem('customSignatureImage') !== 'true' && !hasAnyExtraFeatureInPackage();
    };

    const redirectToEmails = () => {
        getHistory().push('/emails');
    };

    const updateMenuMarkup = () => {
        dispatch({ type: UPDATE_MENU_MARKUP });
    };

    const setUserProfile = (data) => {
        authService.setUserProfile(data, dispatch);
    };

    //render.ből minden
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss:SSZ';
    const content = <SignatureImageInfo />;

    const { getFieldDecorator } = props.form;
    const defaultElement = signatureProfiles.filter((profile) => profile.isDefault)[0];
    const defaultIndex = defaultElement ? defaultElement.id : null;
    const signatureProfilesWithEmptyRows = addEmptyRowsToSignatureProfiles(signatureProfiles);
    const isSecondFactorGlobal = window.config.REACT_APP_SECOND_FACTOR_MODE === 'GLOBAL';
    const enableSecondFactorComponent =
        window.config.REACT_APP_SECOND_FACTOR_MODE !== 'OFF' &&
        (authService.getUserOid() !== 'undefined' || window.config.REACT_APP_SECOND_FACTOR_TYPE === 'ATP');

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    const Content = ({ schema }) => {
        const idToFind = schema.extension === 'png' ? schema.id : 1;
        let base64EncodedImage;
        const result = signatureProfileImages.filter(e => e.id === idToFind);
        if (result === undefined) {
            base64EncodedImage = signatureProfileImages[1];
        } else {
            base64EncodedImage = result[0].image;
        }
        base64EncodedImage.replace(/(\r\n|\n|\r)/gm, '');
        return (
            <div style={{ width: schema.width, height: schema.height }}>
                <div
                    style={{
                        position: 'absolute',
                        width: schema.width,
                        height: schema.height,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(data:image/png;base64,${base64EncodedImage})`
                    }}></div>
            </div>
        );
    };

    const columns = [
        {
            title: translate('home.title'),
            dataIndex: 'profileName',
            render: (text, record) => {
                if (record.isEmptyRow) return '';
                if (record.id === 1) {
                    return translate('default.sign');
                }
                if (record.id === 2) {
                    return translate('invisible.sign');
                }
                return text;
            }
        },
        {
            title: '',
            align: 'right',
            render: (record) => {
                if (record.isEmptyRow) {
                    return '';
                }
                return (
                    record.id !== 2 && (
                        <Popover
                            content={<Content schema={record} />}
                            trigger='hover'>
                            <Icon type='eye' />
                        </Popover>
                    )
                );
            }
        },
        {
            title: '',
            align: 'center',
            render: (profile) => {
                if (profile.id !== 1 && profile.id !== 2 && !profile.isEmptyRow)
                    return (
                        <div>
                            <Button
                                key={'update'}
                                title={translate('buttons.modify.container')}
                                onClick={() => updateSchema(profile)}
                                icon={'edit'}
                                size={'small'}
                                style={{ backgroundColor: '#fadb14', marginRight: 8 }}
                            />
                            <Button
                                type={'danger'}
                                key={'delete'}
                                title={translate('buttons.delete.container')}
                                onClick={() => deleteSchema(profile)}
                                icon={'delete'}
                                size={'small'}
                            />
                        </div>
                    );
                else return <div style={{ height: '24px' }}></div>;
            }
        }
    ];

    const TableElement = ({ defaultIndex }) => {
        const rowSelection = {
            type: 'radio',
            onChange: selectSchema,
            selectedRowKeys: [defaultIndex],
            getCheckboxProps: (profile) => {
                if (profile.isEmptyRow) {
                    return { style: { display: 'none' } };
                }
            }
        };
        return (
            <Table
                rowKey={'id'}
                size='small'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={signatureProfilesWithEmptyRows}
                pagination={{
                    current: schemaTablePage,
                    onChange: onPageChange,
                    defaultPageSize: 5
                }}
            />
        );
    };

    if (!authService.isRegistered()) {
        return null;
    }
    return (
        <WebPage location={props.history.location}>
            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                    <Link to='/'>
                        <Icon
                            type='left-circle'
                            style={{ fontSize: 20 }}
                        />
                        <span style={{ fontSize: 20 }}> {translate('backToHomePage')}</span>
                    </Link>
                </Col>
            </Row>

            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                    <h3 style={{ marginTop: 30 }}>{translate('buttons.sign.text')}</h3>
                    <Box>
                        <Row>
                            <Col
                                span={24}
                                style={{ margin: '0px 0px 6px' }}>
                                <Radio.Group
                                    defaultValue={signMode}
                                    buttonStyle='solid'
                                    onChange={(event) => {
                                        changeSignMode(event.target.value);
                                    }}>
                                    <Radio.Button value='sign'>
                                        <Icon
                                            type={'form'}
                                            style={{ marginRight: 5 }}
                                        />
                                        {translate('switch.signMode.sign.button')}
                                    </Radio.Button>
                                    <Radio.Button value='signUsingQRCode'>
                                        <Icon
                                            type={'qrcode'}
                                            style={{ marginRight: 5 }}
                                        />
                                        {translate('switch.signMode.signUsingQRCode.button')}
                                    </Radio.Button>
                                    <Radio.Button value='signUsingPush'>
                                        <Icon
                                            type={'bell'}
                                            style={{ marginRight: 5 }}
                                        />
                                        {translate('switch.signMode.signUsingPush.button')}
                                    </Radio.Button>
                                </Radio.Group>
                                <Comment
                                    content={
                                        signMode === 'sign' ? (
                                            <React.Fragment>
                                                {translate('switch.signMode.sign.text.beforeFirstLogo')}
                                                <Avatar
                                                    size={20}
                                                    src={microSignerImg}
                                                    style={{ marginLeft: 6, marginRight: 4 }}
                                                    shape='square'
                                                />
                                                {translate('switch.signMode.sign.text.afterFirstLogo')}
                                                <Avatar
                                                    size={20}
                                                    src={microSignerImg}
                                                    style={{ marginLeft: 6, marginRight: 4 }}
                                                    shape='square'
                                                />
                                                {translate('switch.signMode.sign.text.afterSecondLogo')}
                                                <div>
                                                    <a
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        href={getDocuments(
                                                            'promo_for_microsigner',
                                                            currentLanguage                                                        )}>
                                                        {translate('switch.signMode.link.microSigner.text')}
                                                    </a>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <p>
                                                    {signMode === 'signUsingQRCode' ? (
                                                        <React.Fragment>
                                                            {translate(
                                                                'switch.signMode.signUsingQRCode.text.beforeEszigno'
                                                            )}
                                                            <Avatar
                                                                size={20}
                                                                src={eszignoImg}
                                                                style={{ marginLeft: 6, marginRight: 4 }}
                                                            />
                                                            {translate(
                                                                'switch.signMode.signUsingQRCode.text.afterEszigno'
                                                            )}
                                                            <Avatar
                                                                size={20}
                                                                src={pbmImg}
                                                                style={{ marginLeft: 6, marginRight: 4 }}
                                                                shape='square'
                                                            />
                                                            {translate(
                                                                'switch.signMode.signUsingQRCode.text.afterPBM'
                                                            )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {translate(
                                                                'switch.signMode.signUsingPush.text.beforeEszigno'
                                                            )}
                                                            <Avatar
                                                                size={20}
                                                                src={eszignoImg}
                                                                style={{ marginLeft: 6, marginRight: 4 }}
                                                            />
                                                            {translate(
                                                                'switch.signMode.signUsingPush.text.afterEszigno'
                                                            )}
                                                            <Avatar
                                                                size={20}
                                                                src={pbmImg}
                                                                style={{ marginLeft: 6, marginRight: 4 }}
                                                                shape='square'
                                                            />
                                                            {translate(
                                                                'switch.signMode.signUsingPush.text.afterPBM'
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </p>
                                            </React.Fragment>
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                span={24}
                                style={{ margin: '0px 0px 6px 0px' }}>
                                <h3>
                                    {translate('titles.defaultUserCertificate', null, {
                                        missingTranslationMsg: 'Default certificate for signing'
                                    })}
                                </h3>
                                <Comment content={translate('texts.defaultUserCertificate')} />
                                <UserCertificateView signMode={signMode} />
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                span={24}
                                style={{ margin: '0px 0px 6px 0px' }}>
                                <h3>{translate('settings.signatureType')}</h3>
                                <Radio.Group
                                    value={signatureSetting}
                                    buttonStyle='solid'
                                    onChange={(event) => {
                                        changeSignatureSetting(event.target.value);
                                    }}>
                                    <Radio.Button value='AdES_T'>
                                        <Avatar
                                            size={16}
                                            src={eszignoTBadge}
                                            style={{ marginRight: 6 }}
                                            shape='square'
                                        />
                                        {translate('settings.AdES_T_button')}
                                    </Radio.Button>
                                    <Radio.Button value='AdES_A'>
                                        <Avatar
                                            size={16}
                                            src={eszignoBadge}
                                            style={{ marginRight: 6 }}
                                            shape='square'
                                        />
                                        {translate('settings.AdES_A_button')}
                                    </Radio.Button>
                                </Radio.Group>
                                <Comment content={translate('settings.' + signatureSetting)} />
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
            {enableSecondFactorComponent && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                            <span>
                                <h3 style={{ marginTop: 30, marginRight: 20 }}>{translate('secondFactorTitle')}</h3>
                            </span>
                        <Box>
                            <p>{translate('texts.secondFactorDesc')}</p>
                            <Form
                                id={'submitSecondFactorForm'}
                                onSubmit={handleSubmitSecondFactor}>
                                <Form.Item style={{ marginBottom: '0px' }}>
                                    {getFieldDecorator('secondFactor', {
                                        initialValue: authService.getSecondFactor() || isSecondFactorGlobal,
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox
                                            style={{ textAlign: 'left' }}
                                            disabled={isSecondFactorGlobal}
                                            onChange={() => {
                                                isMounted && setChecked(!checked);
                                            }}>
                                            {translate('texts.submitSecondFactor')}
                                        </Checkbox>
                                    )}
                                </Form.Item>
                                <Form.Item style={{ marginTop: '16px' }}>
                                    <Button
                                        id={'submitSecondFactor'}
                                        type='primary'
                                        htmlType='submit'
                                        loading={buttonLoading}>
                                        {translate('buttons.save')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            )}
            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                        <span>
                            <h3 style={{ marginTop: 30, marginRight: 20 }}>{translate('notificationModeTitle')}</h3>
                        </span>
                    <Box>
                        <p>{translate('texts.notificationSettingDesc')}</p>
                        <Form onSubmit={handleSubmitNotificationMode}>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                {getFieldDecorator('emailNotification', {
                                    initialValue: localStorage.getItem('emailNotification') === 'true',
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox style={{ textAlign: 'left' }}>
                                        {translate('texts.emailNotification')}
                                    </Checkbox>
                                )}
                            </Form.Item>
                            {window.config.REACT_APP_PUSH_NOTIFICATIONS_ENABLED && (
                                <Form.Item style={{ marginBottom: '0px' }}>
                                    {getFieldDecorator('pushNotification', {
                                        initialValue: localStorage.getItem('pushNotification') === 'true',
                                        valuePropName: 'checked'
                                    })(
                                        <Checkbox style={{ textAlign: 'left' }}>
                                            {translate('texts.pushNotification')}
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            )}
                            <Form.Item style={{ marginTop: '16px' }}>
                                <Button
                                    id={'submitNotificationMode'}
                                    type='primary'
                                    htmlType='submit'>
                                    {translate('buttons.save')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Box>
                </Col>
            </Row>
            {window.config.REACT_APP_IS_SIGNATURE_PROFILE_MODIFICATION_ENABLED && (
            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                        <span>
                            <h3 style={{ marginTop: 30, marginRight: 20 }}>
                                {translate('signing.profile')}
                                {
                                    <Popover
                                        content={content}
                                        title={translate('signature.image.info.main.title')}>
                                        <Button
                                            size='small'
                                            type={'primary'}
                                            shape='circle'
                                            icon='question'
                                            style={{ marginLeft: 10 }}
                                        />
                                    </Popover>
                                }
                            </h3>
                        </span>
                    <Box>
                        <Row align='middle'>
                            <Col span={24}>
                                <TableElement defaultIndex={defaultIndex} />
                                <Button
                                    id={'createProfile'}
                                    type='primary'
                                    disabled={
                                        window.config.REACT_APP_SIGNATURE_PROFILE_LIMIT <=
                                        signatureProfilesWithEmptyRows.length
                                    }
                                    onClick={() => createProfile()}>
                                    <Icon type='plus' />
                                    {translate('create.profile')}
                                </Button>
                                {window.config.REACT_APP_SIGNATURE_PROFILE_LIMIT <=
                                signatureProfilesWithEmptyRows.length && (
                                    <div style={{ marginTop: 10 }}>
                                        <Icon
                                            type='warning'
                                            theme='twoTone'
                                            twoToneColor='rgb(250, 219, 20)'
                                            style={{ marginRight: 10, fontSize: 20, display: 'inline-block' }}
                                        />
                                        <p style={{ display: 'inline-block' }}>
                                            {translate('create.limitReached', {
                                                profileLimit: window.config.REACT_APP_SIGNATURE_PROFILE_LIMIT
                                            })}
                                        </p>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
            )}
            {authService.getRegistrationType() === 'password' && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <h3 style={{ marginTop: 30 }}>{translate('changePassword.title')}</h3>
                        <Box>
                            <p>{translate('changePassword.description')}</p>
                            <Form onSubmit={handleSubmit}>
                                <FormItem>
                                    {getFieldDecorator('currentPassword', {
                                        rules: [{ required: true, message: translate('form.required') }]
                                    })(
                                        <Input
                                            prefix={<Icon type='lock' />}
                                            placeholder={translate('changePassword.placeholder.currentPassword')}
                                            type={'password'}
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('newPassword', {
                                        rules: [
                                            { required: true, message: translate('form.required') },
                                            { validator: validateToNextPassword }
                                        ]
                                    })(
                                        <Input
                                            prefix={<Icon type='lock' />}
                                            placeholder={translate('changePassword.placeholder.newPassword')}
                                            type={'password'}
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('newPasswordAgain', {
                                        rules: [
                                            { required: true, message: translate('form.required') },
                                            { validator: compareToFirstPassword }
                                        ]
                                    })(
                                        <Input
                                            prefix={<Icon type='lock' />}
                                            placeholder={translate('changePassword.placeholder.confirmNewPassword')}
                                            type={'password'}
                                            onBlur={handleConfirmBlur}
                                        />
                                    )}
                                </FormItem>
                                <Form.Item>
                                    <Button
                                        htmlType={'submit'}
                                        icon={'save'}
                                        id={'submitPasswordChange'}
                                        type={'primary'}>
                                        {translate('changePassword.button.title')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            )}
            {authService.getRegistrationType() === 'ad' && window.config.REACT_APP_AD_WITH_MOBILE_ENABLED && (
                <Row type="flex" justify="center" align="middle">
                    <Col xs={24} md={20} lg={16} xl={10}>
                        <h3 style={{ marginTop: 30 }}>{translate('titles.setADPassword')}</h3>
                        <Box>
                            <br/>
                            <Form onSubmit={handleSubmitSetADPassword}>
                                <FormItem>
                                    {getFieldDecorator('ADPassword', {
                                        rules: [
                                            { required: true, message: translate('registration.requiredField') },
                                            { min: 6, message: translate('form.password.tooShort', { length: 6 }) },
                                        ],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" />}
                                            placeholder={translate('inputs.ADPassword')}
                                            type={'password'}
                                        />
                                    )}
                                </FormItem>
                                <Form.Item>
                                    <Button id={'submitSetADPassword'} type="primary" htmlType="submit">
                                        {translate('adminPage.users.setPassword')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            )}
            {authService.getRegistrationType() === 'oid' && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <h3 style={{ marginTop: 30 }}>{translate('titles.changePassword')}</h3>
                        <Box>
                            <a
                                target={'_blank'}
                                rel='noopener noreferrer'
                                href={window.config.REACT_APP_MICROSEC_PASSWORD_CHANGE_URL}>
                                {translate('link.microsecpasswordchange.text')}
                            </a>
                        </Box>
                    </Col>
                </Row>
            )}
            {window.config.REACT_APP_USER_SERVICE_LEVEL_MANAGER_AVAILABLE && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <div ref={oidRef}></div>
                        <SettingsOid
                            inputRef={textInput}
                            currentLanguage={currentLanguage}
                            updateMenuMarkup={updateMenuMarkup}
                            translate={translate}
                            setUserProfile={setUserProfile}
                        />
                    </Col>
                </Row>
            )}
            {window.config.REACT_APP_IS_TIMESTAMP_CREDENTIAL_MODIFICATION_ENABLED &&
                window.config.REACT_APP_TIMESTAMP_LOGIN_MODE !== 'microsec' && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <h3 style={{ marginTop: 30 }}>{translate('titles.timestamping')}</h3>
                        <Box>
                            <div style={{ lineHeight: '32px' }}>
                                {translate('texts.tsaUrl')}:{' '}
                                <span style={{ fontWeight: 'bold' }}>{window.config.REACT_APP_TSA_URL}</span>
                            </div>
                            <div style={{ lineHeight: '32px', marginBottom: 20 }}>
                                {translate('texts.tsaCredentialsReset')}
                            </div>
                            <Button
                                id={'tsaCredentialsReset'}
                                onClick={() => clearTsaCredentialsAction()}
                                type='danger'
                                disabled={tsa.requireTspCredentials !== false}>
                                {translate('buttons.tsaCredentialsReset')}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            )}
            {window.config.REACT_APP_DEPUTY_FUNCTION_AVAILABLE && (
                <Row
                    type='flex'
                    justify='center'
                    align='middle'>
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <h3
                            style={{ marginTop: 30 }}
                            id='deputy'>
                            {isEmpty(deputy) || isEmpty(deputy.data)
                                ? translate('deputy.title.inactive')
                                : translate('deputy.title.active')}
                        </h3>
                        <Box>
                            {!isEmpty(deputy) && !isEmpty(deputy.data) ? (
                                <React.Fragment>
                                    <Alert
                                        message={translate('deputy.alert.message')}
                                        description={translate('deputy.alert.description')}
                                        type='warning'
                                        showIcon
                                    />

                                    <Row
                                        type='flex'
                                        style={{ marginTop: 12 }}>
                                        <Avatar
                                            size={50}
                                            shape='square'
                                            icon='usergroup-add'
                                            style={{ marginRight: 10 }}
                                        />
                                        <DescriptionItem
                                            title={translate('deputy.descriptionItem.title1')}
                                            content={deputy.data.currentUsersDeputy.deputyEmail}
                                        />
                                    </Row>
                                    <Row
                                        type='flex'
                                        style={{ marginTop: 12, marginBottom: 12 }}>
                                        <Avatar
                                            size={50}
                                            shape='square'
                                            icon='schedule'
                                            style={{ marginRight: 10 }}
                                        />
                                        <DescriptionItem
                                            title={translate('deputy.descriptionItem.title2')}
                                            content={`${moment(
                                                deputy.data.currentUsersDeputy.deputyPeriod.from,
                                                dateFormat
                                            ).format('YYYY.MM.DD. HH:mm')} - ${moment(
                                                deputy.data.currentUsersDeputy.deputyPeriod.to,
                                                dateFormat
                                            ).format('YYYY.MM.DD. HH:mm')}`}
                                        />
                                    </Row>
                                    <Button
                                        id={'deputyButtonDelete'}
                                        type='danger'
                                        onClick={() => {
                                            dispatch(deleteDeputy())
                                                .then(() => {
                                                    createNotificationShort(translate, {
                                                        message: translate(
                                                            'messages.success.deleteDeputy'
                                                        ),
                                                        type: 'success'
                                                    });
                                                })
                                                .catch((err) => {
                                                    serverError(err);
                                                });
                                        }}>
                                        {translate('deputy.button.delete')}
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <p>{translate('deputy.text')}</p>
                                    <Form onSubmit={submitDeputy}>
                                        <FormItem>
                                            {getFieldDecorator('deputyEmail', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate('form.required')
                                                    },
                                                    { type: 'email', message: translate('login.validateEmail') }
                                                ],
                                                normalize: (input) =>
                                                    input !== undefined ? input.toLowerCase() : ''
                                            })(
                                                <Input
                                                    prefix={<Icon type='mail' />}
                                                    placeholder={translate('deputy.input.placeholder')}
                                                    type={'email'}
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem>
                                            {getFieldDecorator('deputyPeriod', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate('form.required')
                                                    }
                                                ]
                                            })(
                                                <RangePicker
                                                    disabledDate={disabledDate}
                                                    disabledTime={disabledRangeTime}
                                                    locale={currentLanguage}
                                                    format={dateFormat}
                                                    showTime={{
                                                        hideDisabledOptions: true,
                                                        defaultValue: [
                                                            moment(moment().add(1, 'h').hour() + ':00:00', 'HH:mm'),
                                                            moment('00:00:00', 'HH:mm')
                                                        ]
                                                    }}
                                                />
                                            )}
                                        </FormItem>
                                        <p>
                                            <b>{translate('deputy.textAlert')}</b>
                                        </p>
                                        <FormItem>
                                            <Button
                                                id={'deputyButtonAdd'}
                                                type='primary'
                                                htmlType='submit'>
                                                {translate('deputy.button.add')}
                                            </Button>
                                        </FormItem>
                                    </Form>
                                </React.Fragment>
                            )}
                        </Box>
                    </Col>
                </Row>
            )}
            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                    <h3 style={{ marginTop: 30 }}>{translate('titles.frequentlyUsedEmailAddresses')}</h3>
                    <Box>
                        <Button
                            id={'redirectToFrequentlyUsedEmailAddresses'}
                            onClick={() => redirectToEmails()}
                            type='primary'>
                            {translate('buttons.redirectToFrequentlyUsedEmailAddresses')}
                        </Button>
                    </Box>
                </Col>
            </Row>
            {window.config.REACT_APP_IS_ACCOUNT_DELETION_ENABLED && (
            <Row
                type='flex'
                justify='center'
                align='middle'>
                <Col
                    xs={24}
                    md={20}
                    lg={16}
                    xl={10}>
                    <h3 style={{ marginTop: 30 }}>{translate('titles.unregister')}</h3>
                    <Box>
                        <div style={{ lineHeight: '32px', marginBottom: 20 }}>{translate('texts.unregister')}</div>
                        <Button
                            id={'unregister'}
                            onClick={() => deleteUserAction()}
                            type='danger'>
                            {translate('buttons.unregister')}
                        </Button>
                    </Box>
                </Col>
            </Row>
                )}
            <Row
                type='flex'
                justify='center'
                align='middle'
                style={{ marginTop: 50 }}>
                <Col>
                    <Button
                        onClick={() => getHistory().push('/changelog')}
                        style={{ border: 0, color: theme.textColor }}>
                        v{process.env.REACT_APP_VERSION}
                    </Button>
                </Col>
            </Row>
        </WebPage>
    );
};

Settings.propTypes = {
    history: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    signatureProfiles: PropTypes.array,
    location: PropTypes.object,
    actions: PropTypes.object
};

export const WrappedSettings = Form.create()(Settings);
export default WrappedSettings;
