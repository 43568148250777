import {TAB_KEY_DEFAULT_CONTAINERS} from '../constants/strings';

/**
 * TODO: store should only store data from the API, think it as a client side database!!
 */
export default {
    confirm: {
        isPassword: false
    },
    containerList: {
        containers: [],
        containerExist: true,
        filtered: false,
        containersChangeCounter: 0,
        containersInSignatureProcess: [],
        selectedContainers: [],
        signatures: [],
        storedFileIdForDownload: '',
        toggleModal: false,
        waitingToSignByOthers: []
    },
    deputy: {},
    documentList: {
        documents: [],
        signatures: []
    },
    documentTitles: [],
    enabled: {
        archiveButton: true,
        checkbox: false
    },
    frequentlyUsedEmailAddresses: [],
    modal: {
        data: '',
        name: '',
        visible: false
    },
    menuMarkup: {
        updateCounter: 0
    },
    signatureProfiles: [],
    signatureProfileImages: [],
    tabs: {
        current: TAB_KEY_DEFAULT_CONTAINERS
    },
    tsa: {
        requireTspCredentials: 'undefined'
    },
    workflowTemplates: [],
};
