/* eslint-disable react-hooks/exhaustive-deps */
import ContainerList from './ContainerList';
import React, { useEffect, useState } from 'react';
import theme from '../../theme';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS } from '../../constants/strings';
import { TABLE_PAGE_SIZE_CONTAINER_LIST, TIMEOUT_MILLISECONDS } from '../../constants/config';
import { Badge, Tabs } from 'antd';
import { createNotification } from '../../helper';
import { fetchDocumentTitles } from '../../actions/documentTitles';
import { getContainers, getContainersInSignatureProcess } from '../../actions/container';
import { getTranslate } from 'react-localize-redux';
import { setCurrentTab } from '../../actions/currentTab';
import { useDispatch, useSelector } from 'react-redux';
import { useMergeState } from '../../utilities/hooks';
import { useDeepCompareEffect } from 'react-use';

export default function ContainerTabs() {
    const dispatch = useDispatch();

    const [isMounted, setIsMounted] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);

    // Default Container List
    const [containerRequestOptions, setContainerRequestOptions] = useMergeState({
        count: 0,
        filter: '',
        limit: TABLE_PAGE_SIZE_CONTAINER_LIST,
        offset: 0,
        order: 'DESC',
        orderBy: 'created'
    });

    // In Signature Process List -> ISP
    const [containerISPRequestOptions, setContainerISPRequestOptions] = useMergeState({
        count: 0,
        filter: '',
        limit: TABLE_PAGE_SIZE_CONTAINER_LIST,
        offset: 0,
        order: 'DESC',
        orderBy: 'created'
    });

    const containers = useSelector((state) => state.containerList.containers);
    const containersChangeCounter = useSelector((state) => state.containerList.containersChangeCounter);
    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const currentTab = useSelector((state) => state.tabs.current);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        if (!isMounted) {
            getContainerList().then(() => {
                setIsMounted(true);
            });
        }
        return () => setIsMounted(false);
    }, []);

    useEffect(() => {
        // FIXME
        // This is a not-so temporary solution to avoid infinite request loops when
        // switching fast between containers.
        isMounted && setIsTableLoading(true);
        isMounted && setTimeout(() => isMounted && setIsTableLoading(false), TIMEOUT_MILLISECONDS);
    }, [currentTab]);

    useEffect(() => {
        if (isMounted && !isTableLoading) {
            getDefaultContainers();
            containers.length > 0 && dispatch(setCurrentTab(TAB_KEY_DEFAULT_CONTAINERS));
        }
    }, [containersChangeCounter]);

    useDeepCompareEffect(() => {
        isMounted && !isTableLoading && getDefaultContainers();
    }, [containerRequestOptions]);

    useDeepCompareEffect(() => {
        if (isMounted && !isTableLoading) {
            getSignatureProcessContainers();
            containersInSignatureProcess.length > 0 && dispatch(setCurrentTab(TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS));
        }
    }, [containersInSignatureProcess,containerISPRequestOptions]);

    function getDefaultContainers() {
        setIsTableLoading(true);
        dispatch(
            getContainers(containerRequestOptions)
        )
            .then((response) => {
                setContainerRequestOptions({ count: response.allContainerCount });
                setIsTableLoading(false);
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('notifications.getData.failure'),
                    type: 'error'
                });
            });
    }

    function getSignatureProcessContainers() {
        setIsTableLoading(true);
        dispatch(
            getContainersInSignatureProcess(containerISPRequestOptions)
        )
            .then((response) => {
                setContainerISPRequestOptions({ count: response.allContainerCount });
                setIsTableLoading(false);
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('notifications.getData.failure'),
                    type: 'error'
                });
            });
    }

    function getContainerList() {
        return dispatch(
            getContainers(containerRequestOptions)
        )
            .then((response) => {
                setContainerRequestOptions({ count: response.allContainerCount });
                dispatch(
                    getContainersInSignatureProcess(containerISPRequestOptions)
                )
                    .then((responseInSignatureProcess) => {
                        setContainerISPRequestOptions({ count: responseInSignatureProcess.allContainerCount });
                        if (
                            responseInSignatureProcess.containers.length > 0 &&
                            currentTab !== TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS &&
                            !isMounted
                        ) {
                            !isTableLoading && dispatch(setCurrentTab(TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS));
                        } else if (
                            responseInSignatureProcess.containers.length === 0 &&
                            response.containers.length > 0 &&
                            currentTab !== TAB_KEY_DEFAULT_CONTAINERS &&
                            !isMounted
                        ) {
                            !isTableLoading && dispatch(setCurrentTab(TAB_KEY_DEFAULT_CONTAINERS));
                        }
                    })
                    .catch((error) => {
                        createNotification(translate, error.response.data.code, {
                            message: translate('notifications.getData.failure'),
                            type: 'error'
                        });
                    });
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('notifications.getData.failure'),
                    type: 'error'
                });
            })
            .finally(
                dispatch(fetchDocumentTitles())
                    .catch((error) => {
                        createNotification(translate, error.response.data.code, {
                            message: translate('notifications.getData.failure'),
                            type: 'error'
                        });
                    })
            );
    }

    function onTabChange(activeKey) {
        if (isMounted) {
            dispatch(setCurrentTab(activeKey));
        }
    }

    return (
        <Tabs
            activeKey={currentTab}
            className={'containerTabs'}
            destroyInactiveTabPane={true}
            onChange={(activeKey) => onTabChange(activeKey)}
            type={'card'}>
            <Tabs.TabPane
                key={TAB_KEY_DEFAULT_CONTAINERS}
                tab={translate(`container.list.${TAB_KEY_DEFAULT_CONTAINERS}.title`)}>
                <ContainerList
                    containerList={containers}
                    containerRequestOptions={containerRequestOptions}
                    isTableLoading={isTableLoading}
                    setContainerRequestOptions={setContainerRequestOptions}
                    setIsTableLoading={setIsTableLoading}
                />
            </Tabs.TabPane>
            <Tabs.TabPane
                key={TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS}
                tab={
                    <Badge
                        count={containerISPRequestOptions.count}
                        offset={[15, -2]}
                        style={{ backgroundColor: theme.errorColor }}>
                        {translate(`container.list.${TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS}.title`)}
                    </Badge>
                }>
                <ContainerList
                    containerList={containersInSignatureProcess}
                    containerRequestOptions={containerISPRequestOptions}
                    isTableLoading={isTableLoading}
                    setContainerRequestOptions={setContainerISPRequestOptions}
                    setIsTableLoading={setIsTableLoading}
                />
            </Tabs.TabPane>
        </Tabs>
    );
}
