import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import theme from '../../theme';
import { Button, Icon } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { createNotificationShort } from '../../helper';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfPreviewDeprecated(props) {
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const file = useMemo(
        () => ({
            url: props.url,
            withCredentials: true,
            httpHeaders: { Authorization: `Bearer ${localStorage.getItem('JWT')}` }
        }),
        [props.url]
    );

    return (
        <Document
            file={file}
            onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
            loading={
                <div style={{ textAlign: 'center', margin: '16px 0', color: theme.defaultColor }}>
                    <Icon
                        type="loading"
                        style={{ fontSize: 32 }}
                    />
                    <span style={{ display: 'block' }}>{props.translate('previewLoading')}</span>
                </div>
            }
            onLoadError={() => {
                createNotificationShort(this.props.translate, {
                    message: props.translate('notifications.pdfLoadingError.message'),
                    type: 'error'
                });
            }}>
            <nav
                style={{
                    textAlign: 'center',
                    padding: '16px 0 16px 0',
                    marginBottom: 16,
                    backgroundColor: '#FAFAFA',
                    borderBottom: '1px solid #e8e8e8'
                }}>
                <Button
                    type={'primary'}
                    style={{ marginRight: 10 }}
                    disabled={pageNumber <= 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                    icon={'left'}
                />
                <span style={{ color: theme.textColor }}>
                    {pageNumber}/{numberOfPages}
                </span>
                <Button
                    type={'primary'}
                    style={{ marginLeft: 10 }}
                    disabled={numberOfPages <= pageNumber}
                    onClick={() => setPageNumber(pageNumber + 1)}
                    icon={'right'}
                />
            </nav>
            <ScrollContainer
                className="scroll-container"
                nativeMobileScroll={false}
                hideScrollbars={false}>
                <Page
                    pageNumber={pageNumber}
                />
            </ScrollContainer>
        </Document>
    );
}

PdfPreviewDeprecated.propTypes = {
    translate: PropTypes.func,
    url: PropTypes.string
};
